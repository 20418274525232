<template>
  <div class="productContent">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">密码修改</div>
    <div class="card">
        <div class="catalog">
            <p>用户名</p>
            <p>{{profileUserInfo.userName}}</p>
        </div>
        <div class="catalog">
            <p>姓名</p>
            <p>{{profileUserInfo.name}}</p>
        </div>
        <!-- <div class="catalog">
            <p>所属城市</p>
            <p>{{profileUserInfo.cityName}}-{{profileUserInfo.districtName}}</p>
        </div>    -->
        <div class="catalog">
            <p>旧密码</p>
            <input type="password" placeholder="请输入旧密码" v-model="newPwd.oldPassword">
        </div>
        <div class="catalog">
            <p>新密码</p>
            <input type="password" placeholder="8-20个字符(字母和数字)" v-model="newPwd.password">
        </div>
        <div class="catalog">
            <p>确认新密码</p>
            <input type="password" placeholder="请再输入一次密码" v-model="newPwd.password2">
        </div>
        <div class="del-btn">
            <button @click="submit()">修改密码</button>
        </div>
    </div>
    
  </div>
</template>
<script>
import { apiGoEditPw,apiSaveUserPwd } from '../../api/axios';
import { reactive,ref,toRefs,toRaw } from 'vue';
import { Toast } from 'vant';
import { useRouter } from 'vue-router';
import {useStore} from 'vuex';
export default {
   setup() {
       let router = useRouter();
       let store = useStore();
       let routerName = router.currentRoute.value.query.name;
       const state = reactive({
           profileUserInfo: '',
           newPwd: {
               password: '',
               oldPassword: '',
               password2: ''
           }
       });
       apiGoEditPw({userName:routerName}).then(res => {              
             if(res.code == '1'){
               state.profileUserInfo = res.info.profileUserInfo;  
              }
              else {
                Toast(res.msg);
              }
        });
        let testPassword =/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{12,20}$/;
        const submit = () =>{
            if(!state.newPwd.password) {
               Toast('请先输入新密码！')
               return;
            }
            if(!testPassword.test(state.newPwd.password)) {
               Toast('密码需12-20个字符组成，且必须同时包含数字、小写字母、大写字母和特殊字符的三种及以上')
               return;
           }
            if(state.newPwd.password != state.newPwd.password2) {
               Toast('两次密码输入不一致！')
               return;
            }
            let postObj = {
                userName: routerName,
                currentPassword: state.profileUserInfo.currentPassword,
                password: state.newPwd.password,
                oldPassword: state.newPwd.oldPassword
            }
            apiSaveUserPwd(postObj).then(res => {                 
             if(res.code == '1'){
                    Toast({
                        duration: 5000,
                        message: '修改成功',
                    });
                    router.push({ name:'login',replace:true}); 
              }
              else {
                Toast.fail(res.msg);
              }
        });
        }

        const goBack = () =>{
        if(store.state.isiOS) {
          try {
            window.webkit.messageHandlers.back.postMessage(null);
          } catch (e) {
            alert('跳转返回ios失败');
          }
        }else {
            router.push({
                name: 'User'
            })
        }
        }
       return {
           ...toRefs(state),
           submit,
           goBack
       }
  }
}
</script>
<style lang="less" scoped>
.productContent {
    padding-top: 12.5vw;
    .card {
        padding: 2vw 4vw;
        .catalog {
            display: flex;
            align-items: center;
            p:first-child {
                width: 26vw;
                color: #666666;
                font-size: 4vw;
                padding: 3vw 0;
            }
            p:last-child {
                width: 55vw;
                font-size: 4vw;
                padding-left: 4vw;
                // padding: 3vw 0;
            }
            input {
                background-color: #f4f8fe;
                border: 0;
                margin-left: 4vw;
                width: 55vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
            }

            ::-webkit-input-placeholder {
              color: #336699;
            }
            ::-moz-placeholder {
              color: #336699;
            }
            :-ms-input-placeholder {
              color: #336699;
            }
            :-moz-placeholder {
              color: #336699;
            }

        }
        .del-btn {
            display: flex;
            justify-content: center;
            padding: 4vw;
            button {
                background: linear-gradient(to right, #ff9900, #ff6700);
                color: #fff;
                font-size: 4.5vw;
                padding: 2vw 6vw;
                border-radius: 5vw;
                border: 0;
            }
        }
        
    }

    
}
</style>
